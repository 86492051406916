<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isOverlaySidebarActive }"
      >
        <financeiro-sidebar
          @change-status="changeStatus"
          :is-overlay-sidebar-active.sync="isOverlaySidebarActive"
        />
      </div>

      <div
        class="body-content-overlay"
        :class="{ show: isOverlaySidebarActive }"
        @click="isOverlaySidebarActive = false"
      />
      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <!-- Filters -->
          <financeiro-filters
            :is-overlay-sidebar-active.sync="isOverlaySidebarActive"
            :status-filter.sync="statusFilter"
            :start-filter.sync="startFilter"
            :end-filter.sync="endFilter"
            :status-options="statusOptions"
          />
          <div class="card-body pb-0">
            <time-line-financeiro />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment";
import { onUnmounted } from "@vue/composition-api";
import financeiroStoreModule from "./financeiroStoreModule";
import FinanceiroFilters from "./FinanceiroFilters.vue";
import FinanceiroSidebar from "./financeiro-sidebar/FinanceiroSidebar.vue";
import TimeLineFinanceiro from "./timeline-financeiro/TimeLineFinanceiro.vue";

export default {
  components: {
    FinanceiroFilters,
    FinanceiroSidebar,
    TimeLineFinanceiro,
  },

  watch: {
    startDate() {
      this.fetchData();
    },
    endDate() {
      this.fetchData();
    },
    statusFilter() {
      this.fetchData();
    },
  },

  computed: {
    startDate() {
      return moment(this.startFilter, "DD/MM/YYYY").toISOString();
    },

    endDate() {
      return moment(this.endFilter, "DD/MM/YYYY").toISOString();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.dispatch("financeiro/fetchData", {
        dates: { start: this.startDate, end: this.endDate },
        filtro: this.statusFilter,
      });
    },

    changeStatus(status) {
      this.statusFilter = status;
      this.isOverlaySidebarActive = false;
    },
  },

  setup() {
    const FINANCEIRO_APP_STORE_MODULE_NAME = "financeiro";

    // Register module
    if (!store.hasModule(FINANCEIRO_APP_STORE_MODULE_NAME))
      store.registerModule(
        FINANCEIRO_APP_STORE_MODULE_NAME,
        financeiroStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCEIRO_APP_STORE_MODULE_NAME))
        store.unregisterModule(FINANCEIRO_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "Todas", value: "todas" },
      { label: "Recebida", value: "recebida" },
      { label: "A receber", value: "receber" },
    ];

    const statusFilter = "todas";
    const startFilter = moment()
      .startOf("month")
      .format("DD/MM/YYYY");
    const endFilter = moment()
      .endOf("month")
      .format("DD/MM/YYYY");

    return {
      statusFilter,
      startFilter,
      endFilter,
      statusOptions,
      isOverlaySidebarActive: false,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
