<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        <b-link
          class="nav-link text-secondary d-lg-none d-inline-block"
          @click="
            $emit('update:isOverlaySidebarActive', !isOverlaySidebarActive)
          "
        >
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Status da transação</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
            :clearable="false"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group label="De" label-for="of-date">
            <flat-pickr
              id="of-date"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y' }"
              :value="startFilter"
              placeholder="DD/MM/YYYY"
              @input="(val) => $emit('update:startFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group label="Até" label-for="from-date">
            <flat-pickr
              id="from-date"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y' }"
              :value="endFilter"
              placeholder="DD/MM/YYYY"
              @input="(val) => $emit('update:endFilter', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardHeader,
  BFormGroup,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BLink,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BCardHeader,
    BCardBody,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    isOverlaySidebarActive: {
      type: Boolean,
      require: true,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    startFilter: {
      type: String,
      default: null,
    },
    endFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.flatpickr-input {
  -webkit-appearance: none; /* your custom styles here */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
