<template>
  <div v-if="rows">
    <b-card>
      <b-card-body>
        <vue-paginate-scroll
          v-if="rows"
          :src="items"
          :per-scroll="perPage"
          :observerOptions="{ threshold: 0.75 }"
        >
          <template slot-scope="{ data }">
            <app-timeline>
              <app-timeline-item
                icon="DollarSignIcon"
                v-for="(transacao, key) in data"
                v-bind:key="key"
                :variant="transacao.pago ? 'success' : 'warning'"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                >
                  <h6>
                    R$
                    {{ $options.filters.formatMoney(transacao.materia_valor) }}
                    - {{ transacao.tipo_2 }}
                  </h6>
                  <small
                    class="font-small-3"
                    :class="transacao.pago ? 'text-success' : 'text-warning'"
                    >{{ transacao.date_at | moment("DD/MM/YYYY - H:mm") }}
                    <br />
                    Duração: {{ transacao.duracao }}</small
                  >
                </div>
                <p v-if="transacao.statusInfo">{{ transacao.titulo }}</p>
                <div v-html="transacao.info" v-if="transacao.statusInfo"></div>
                <small class="font-small-3">{{ transacao.local.nome }}</small>
                <div class="font-small-2">
                  <b-link
                    :href="
                      `https://www.google.com/maps/dir//${transacao.local.lat},${transacao.local.lng}`
                    "
                    target="_blank"
                    >{{ transacao.local.endereco }}</b-link
                  >
                </div>
                <p>
                  <b-img
                    v-if="transacao.nota_fiscal !== null"
                    :src="require('@/assets/images/icons/pdf.png')"
                    height="auto"
                    width="20"
                    class="mr-1"
                  />
                  <span
                    class="align-bottom"
                    v-if="transacao.nota_fiscal !== null"
                    >Nota_fiscal.pdf</span
                  >
                </p>
              </app-timeline-item>
            </app-timeline>
          </template>
        </vue-paginate-scroll>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BImg,
  BLink,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "TimeLineCalendar",
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
    BImg,
    BLink,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 15,
      itemCollapsed: {},
    };
  },

  computed: {
    transacoes() {
      return this.$store.state.financeiro.data;
    },

    items() {
      let transacoes = this.transacoes;

      return transacoes.sort(this.sortDate);
    },

    rows() {
      return this.transacoes.length;
    },
  },

  methods: {
    sortDate(a, b) {
      let created_atA = moment(b.date);
      let created_atB = moment(a.date);

      if (created_atA.unix() < created_atB.unix()) return -1;
      if (created_atA.unix() > created_atB.unix()) return 1;
      return 0;
    },
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
};
</script>
