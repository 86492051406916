<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <div class="mt-0">
        <b-row>
          <b-card
            bg-variant="success"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="$emit('change-status', 'recebida')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Total recebido</div>
              <h4 class="text-white">
                R$ {{ $options.filters.formatMoney(transacoesPagas) }}
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
        <b-row>
          <b-card
            bg-variant="warning"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="$emit('change-status', 'receber')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Total a receber</div>
              <h4 class="text-white">
                R$ {{ $options.filters.formatMoney(transacoesPendentes) }}
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCard, BCardTitle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCard,
    BCardTitle,
  },
  computed: {
    transacoes() {
      return this.$store.state.financeiro.data;
    },

    transacoesPagas() {
      return this.$store.state.financeiro.data
        .filter((transacao) => transacao.pago === true)
        .reduce((sum, transacao) => sum + transacao.materia_valor, 0);
    },

    transacoesPendentes() {
      return this.$store.state.financeiro.data
        .filter((transacao) => transacao.pago === false)
        .reduce((sum, transacao) => sum + transacao.materia_valor, 0);
    },
  },
};
</script>

<style></style>
